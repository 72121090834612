<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div v-if="(id > 0)" class="col-sm-6">
                    <h1>{{ $t('general.cliente') }}</h1>
                </div>
                <div v-else class="col-sm-6">
                    <h1>{{ $t('general.nuevocliente') }}</h1>
                </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación -->
                <div  v-if="(id > 0)" class="col-md-2">
                    <div class="card bg-gradient-primary">
                        <div class="card-body box-profile">
                            <h3 class="profile-username text-center">{{cliente.nombre}}</h3>
                            <p class="text-muted text-center text-white">{{cliente.razon_social}}</p>
                        </div>
                    </div>
                    <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                            <ul class="list-group list-group-unbordered mb-3">
                            <li class="list-group-item">
                                <b>{{ $t('general.servicios') }}</b> <a class="float-right">301</a>
                            </li>
                            <li class="list-group-item">
                                <b>{{ $t('general.serviciosactivos') }}</b> <a class="float-right">45</a>
                            </li>
                            </ul>
                        </div>
                    </div>
                    <div class="callout callout-info">
                    <h5>{{ $t('general.ultimamodificacion') }}</h5>
                    <p>{{cliente.fecha_actualiza_cliente}}</p>
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#datos_cliente" data-toggle="tab">{{ $t('general.datos') }}</a></li>  
                            </ul>
                        </div>
                    </div>
                    <br>
                    <div class="tab-content">
                        <div class="active tab-pane" id="datos_cliente">
                            <cliente-datos :cliente="cliente" :id="cliente.id"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import ClienteDatos from './datos/ClienteDatos.vue'
export default {
    props: ['id'],
    components: {
        'cliente-datos': ClienteDatos,
    },
    data() {
        return {
            cliente:[],
        }
    },
    methods: {
        async obtenerCliente(id) {
            /* GET  modulos/pwgsapi/index.php/clientes/:id */
            console.log('entraclientee');
            const api = new PwgsApi();
            const datos = await api.get("clientes/" + id);
            this.cliente = datos.datos;
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            let nombrecliente = this.cliente.nombre;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana=='/clientes/'+ventanaid) {
                    this.$store.getters.ventanas[i].name = nombrecliente;
                    this.$store.getters.ventanas[i].alt = "Cliente: " + this.cliente.nombre + " || " + this.cliente.direccion;
                }
            }
        },
    },
    mounted() {
        this.obtenerCliente(this.id);
    },
    watch: {
        id() {
            this.obtenerCliente(this.id);
        },
        $route() {
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            let nombrecliente = this.cliente.nombre;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/clientes/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = nombrecliente;
                    this.$store.getters.ventanas[i].alt = "Cliente: " + this.cliente.nombre + " || " + this.cliente.direccion;
                }
            }
        }
   
    }

    
}
</script>